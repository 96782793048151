$breakpoint-small: "  (min-width: 850px)";
$breakpoint-tablet: "(min-width: 1100px)";
$farbe-weiss: #FFFFFF;
$farbe-text: #272726;
$farbe-grau: #878787;
$gap-global: 30px;
$gap-oben: 39px;
$gap-unten:35px;
$gap-mobile: 30px;

@font-face {
    font-family: 'berthold_baskervillemedium';
    src: url('berthold-webfont.eot');
    src: url('berthold-webfont.eot?#iefix') format('embedded-opentype'), url('berthold-webfont.woff') format('woff'), url('berthold-webfont.ttf') format('truetype'), url('berthold-webfont.svg#berthold_baskervillemedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
}


body,
html {
    height: 100%;
    min-height: 100%;
}

body {
    &.desktop {}
}

#mobile-bildcontainer {
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5555;

    @media #{$breakpoint-small} {
        display: none;
    }

    #mobile-bilder-close {
        position: absolute;
        right: #{$gap-mobile - 2px};
        top: 20px;
        width: 50px;
        z-index: 12222;
        border-bottom: none !important;

        img {
            width: 100%;
            height: auto;
        }
    }
}


#mobile-navi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5000;

    @media #{$breakpoint-small} {
        display: none;
    }

    #wrap-hamburger {
       // background-color: $farbe-weiss;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 5010;
        padding: 20px $gap-mobile;
        text-align: right;
        line-height: 0;

        #hamburger {
            height: auto;
        }
    }

    ul {
        position: absolute;
        padding-top: #{3 * $gap-mobile};
        width: 100%;
        min-height: 100vh;
        list-style: none;
        display: none;
        background-color: $farbe-weiss;
        padding-bottom: 1.5em;

        li {
            padding: 0 $gap-mobile 0 $gap-mobile;

            a {
                display: inline-block;
                text-decoration: none;
               // border-bottom: none;
                margin-bottom: 0.8936em;
                
                &#link-aktuell-mob,
                &#link-attuale-mob,
                &#link-feriengaeste-mob,
                &#link-kontakt-mob
                {
                    margin-bottom: 2.3em;    //1.7872em;   //4em = 96px     
                }

                &:focus,
                &:hover,
                &:aktiv {
                    border-bottom: 1px solid $farbe-text;
                }


                
            }
        }
    }
}

.bild-bt-wrap {
    text-align: right;
    margin-top: 0.8936em;
    font-size: 1.0625rem; // 17/16
    line-height: 1.26;
    margin-bottom: 15px;
    @media #{$breakpoint-small} {
        display: none;
    }

    #bild-bt {
        color: $farbe-grau;
    }
}
.start {
    .bild-bt-wrap {
        display: none;
    }
}

.aktuell {
    .bild-bt-wrap {
        display: none;
    }

    .akk-inhalt {
        .bild-bt-wrap {
            display: block;

            @media #{$breakpoint-small} {
                display: none;
            }
        }
    }
}




#seite {
    @media #{$breakpoint-small} {
        min-width: 685px;
        width: 100%;
        max-width: 100%;
        position: absolute;
        height: 100%;
        max-height: 100%;
        

    }
}

#leitsatz {
    position: absolute;
    left: -9999px;
    top: -500px;
}

#content-wrap {
    @media #{$breakpoint-small} {
        width: 481px;
    }
    position: relative;
    position: relative;
    border-bottom: 1px solid transparent;
}

#content-bg {
    background-color: $farbe-weiss;
    margin: 0 0 50px 0;
}

#content {
    padding: 90px 30px 35px 30px;
    z-index: 2111;
    @media #{$breakpoint-small} {
        padding: 39px 30px 35px 30px;
    }
}

.aktuell {
    #content {
        padding-top: 76px;

        @media #{$breakpoint-small} {
           padding-top: 20px;
           padding-bottom: 13px;
        }
    }
}

#content-close-wrap {
    display: none;
    position: absolute;
    top: 10px;
    right: 30px;
    text-align: right;

    @media #{$breakpoint-small} {
        display: block;
    }

    span {
        display: none;
    }
}

body {
    &.start {
        background-image: url(../img/vosa_logo_200_start.jpg);
        background-size: 80vw auto;
        background-position: center center;
        background-repeat: no-repeat;

        @media (orientation : landscape) {
            background-size: auto 70vh;
        }

        @media #{$breakpoint-small} {
            background-image: none;
        }


        @media #{$breakpoint-small} {
            background-image: none;
        }
    }
}


.start {
    #content-close-wrap { 
        display: block;
        top: 21px;
        right: 30px;
        position: fixed;

        @media #{$breakpoint-small} {
            top: 10px;
            position: absolute;
        }
        
    }
    #content-inner {
        img {
            width:90%;
            max-width: 358px;
            height: auto;
            margin: 0 auto;
            display: none;
            @media #{$breakpoint-small} {
                margin: 20px 0 0 0;
                max-width: 358px;
                display: inline;
                margin: 29px 0 0 -2px;
            }
        }
    }
}




// verschieben auf Tablets nicht machen
.desktop {
    #content-close-wrap {
        span {
            display: inline;
        }
    }
}

#kopfnavigation {
    display: none;
    position:fixed;
    top: 0px;
    width: 100%;
    height: auto;
    min-height: 39px;
    text-align: center;
    background-color: $farbe-weiss;
    z-index: 2222;
    color: $farbe-text;

    @media #{$breakpoint-small} {
        display: block;
    }
}

#fussnavigation {
    display: none;
    position:fixed;
    bottom: 0px;
    width: 100%;
    height: auto;
    min-height: 39px;
    text-align: center;
    background-color:$farbe-weiss;

    @media #{$breakpoint-small} {
        display: block;
    }
}

// akkordeon für aktuelles

.akk-titel {
    background-image: url(../img/vosadidentro_mobil_dropdown.svg);
    background-size: 46px auto ; // width height
    background-position: right -2px top 1px; // x y
    background-repeat: no-repeat;
    @media #{$breakpoint-small} {
        background-size: 50px auto ; // width height 
        background-position: right -2px top 4px; // x y
        
    }

  }
  .akk-inhalt {
      display: none;
  // max-height: 0;
  // overflow: hidden;
  //  transition: max-height 2s;
  }

.akk-wrap {
 border-bottom: 1px solid $farbe-text;
 margin: 0 -30px;
 padding: 0 30px;

 

 &.akkopen {
     .akk-titel {
        background-position: right -1px top 0px; // x y
        background-image: url(../img/vosadidentro_mobil_dropup.svg);
        @media #{$breakpoint-small} {
            background-position: right -1px top 1px; // x y
        }
     }
     .akk-inhalt {
       //  max-height: 500px;
     }
 }
 
}

#content-inner {
    .akk-wrap  {
        &.akk-last  {
            border-bottom: 1px solid $farbe-weiss !important;
        }
    }
}





/*****  Shhriften Formatierung  *****/

body {
    /*font-family:"Baskerville Medium", 'Times New Roman', Times, serif; */
    font-family: 'berthold_baskervillemedium', 'Times New Roman', Times, serif;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1.5;
    color: $farbe-text;
    letter-spacing: 0.07em;
    font-weight: 100;
    cursor: hand;
    cursor: pointer;
}

#seite {
    font-size: 100%;
    line-height:1.5;
    letter-spacing: 0.07em;
}

#content-wrap {
    cursor: default;
}


#content-close-wrap {

    font-size: 0.84375rem;
    line-height: 1.5;
    letter-spacing: 0.05em;
}

.start #content-close-wrap
 {
    font-size: 1.0625rem; // 17/16
    line-height: 1.26;
    @media #{$breakpoint-small} {
        font-size: 0.84375rem;
        line-height: 1.5;
        letter-spacing: 0.05em;
    }

}



#content-inner p,
#content-inner h1,
#content-inner ul,
.bild-bt-wrap,
#mobile-navi ,
 {
    font-size: 1.0625rem; // 17/16
    line-height: 1.3;
    @media #{$breakpoint-small} {
        font-size: 1.46875rem; //23.5/16
        line-height: 1.26;
    }

}



#content-inner {
    h1 {
        font-weight: normal;
        margin-bottom: 0.8936em;  // 21/23.5
        &.akk-titel {
            margin-bottom: 0.8936em; 
            margin-top:  0.8085em; //19/23.5 
            cursor: pointer;
        }
        
    }

}





#content-inner p.mitabstand {
    margin-bottom: 0.8936em;
}

#content-inner ul {
    list-style: none;
}

#content-inner ul li {
    padding: 0 0 0 1em;
    background-image: url(../img/aufzaehlstrich.png);
    background-repeat: no-repeat;
    background-position: 0 0.55em;
}

span.at {
    font-size: 0.582em;
}

#kopfnavigation,
#fussnavigation {
    font-size: 0.84375rem;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    cursor: default;
}

#kopf-navi,
#fuss-navi {
    position: relative;
    margin: 0 0 0 0;
    padding: 0.77em 0 0.67em 0;
    padding: 0.77em 0 0.59em 0;
}

#fuss-navi .lang-wrap {
    display: inline-block;
    margin-left: 73px;
}

a:link,
a:visited,
a:focus,
a:hover {
    color: $farbe-text;
    text-decoration: none;
    border-bottom: 1px solid transparent;
}

a:focus,
a:hover,
a.aktiv {
    border-bottom: 1px solid $farbe-text;
}

#fuss-navi a,
#kopf-navi a {
    margin: 0.08em 1em 0.08em 1em;
    display: inline-block;
    padding: 0 0 0.08em 0;
}

#content-inner a:link,
#content-inner a:visited,
#content-inner a:focus,
#content-inner a:hover {
    color: $farbe-grau;
    text-decoration: none;
    border-bottom: 0;
}

#content-inner a:focus,
#content-inner a:hover,
#content-inner {
    color: $farbe-text;
}

a.weiss {
    color: $farbe-weiss;
    border-color: $farbe-weiss;
}

@media only screen and (max-width: 720px) {
    #content-bg {
        margin: 0 0 70px 0;
    }
}

@media print {

    #content-close,
    #kopfnavigation,
    #fussnavigation,
    #hamburger,
    .bild-bt-wrap
    
    {
        display: none;
    }

    #content-wrap {
        position: relative !important;
        width: 481px;
        top: 0 !important;
        left: 0 !important;
        background-color: transparent;
    }

    #content-bg {
        background-color: transparent;
        margin: 0;
    }

    div.backstretch {
        display: none;
    }
}


/* aktuell Buttons abstellen */
#link-aktuell,
    #link-aktuell-mob,
    #link-attuale,
    #link-attuale-mob
    
    {
      display:none !important;
    }

